<template>
  <div>
    <list v-if="view==='list'" :people="people" :group="group" :sortable="sortable" @reload="reload" ref="list"></list>
    <grouped v-else-if="isGrouped" :people="people" :group="group" :view="view" ref="grouped"></grouped>
    <card v-else-if="view==='card'" :people="people" ref="card"></card>
    <photo v-else-if="view==='photo'" :people="people" ref="photo"></photo>
    <div v-else>{{ view }}</div>
  </div>
</template>
<script>
import List from './List'
import Card from './Card'
import Grouped from './Grouped'
import Photo from './Photo'
import { computed } from '@vue/composition-api'

export default {
  components: { List, Card, Grouped, Photo },
  props: {
    people: {
      type: Array,
      default: () => { return [] }
    },
    group: String,
    sortable: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit, refs }) {
    const view = computed(() => { return root.$store.state.greatscots.listView })
    const isGrouped = computed(() => props.group !== '' && props.group != null && typeof (props.group) !== 'undefined')

    function reload (args) {
      emit('reload', args)
    }

    function print () {
      const win = window.open()
      if (!win) {
        alert('It looks like you are blocking popups. To ensure good printing, we have to create a new window. Please enable popups and press the print button again.')
        return
      }
      win.addEventListener('load', () => { console.log('loaded'); win.print() }, true)
      win.document.write('<h1 style="text-align:center">GreatScots Export</h1>')
      if (view.value === 'list') {
        refs.list.print(win)
      } else if (props.group.value !== '' && props.group.value != null && typeof (props.group.value) !== 'undefined') {
        refs.grouped.print(win)
      } else if (view.value === 'card') {
        refs.card.print(win)
      } else if (view.value === 'photo') {
        refs.photo.print(win)
      }
    }

    return {
      view,
      isGrouped,
      reload,
      print
    }
  }
}
</script>
