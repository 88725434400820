<template>
  <v-container>
    <v-row>
      <v-col v-for="person in sortedPeople" :key="person._id" :cols="6" :sm="sm" :md="md" :lg="lg" :xl="xl" @click="emitClick(person._id)">
        <img :src="person.photo || '/img/no.jpg'" style="max-width:140px;width:100%" />
        <h4>{{ person.name.first }} {{ person.name.last }}</h4>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
 @media print {
   div.col-6 {
     max-width: 25%;
   }
 }
</style>
<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    people: {
      type: Array,
      default: () => { return [] }
    },
    doubleCols: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const sortBy = computed(() => root.$store.state.greatscots.resultOptions.sortBy)
    const sortedPeople = computed(() => props.people.sort((a, b) => {
      for (const f of sortBy.value) {
        const [top, bottom] = f.split('.')
        if (a[top][bottom] < b[top][bottom]) return -1
        else if (a[top][bottom] > b[top][bottom]) return 1
      }
      return 0
    }))

    function emitClick (id) {
      emit('click', id)
    }

    const cols = computed(() => {
      return props.doubleCols ? 6 : 3
    })
    const sm = computed(() => { return props.doubleCols ? 6 : 4 })
    const md = computed(() => { return props.doubleCols ? 6 : 3 })
    const lg = computed(() => { return props.doubleCols ? 4 : 2 })
    const xl = computed(() => { return props.doubleCols ? 2 : 1 })

    function print (win) {
      for (const item of sortedPeople.value) {
        win.document.write('<div style="display:inline-block;width:130px;height:180px;vertical-align:top;page-break-inside:avoid;margin-right:.5em">')
        win.document.write('<img src="' + (item.photo || process.env.VUE_APP_BASE_URL + '/img/no.jpg') + '" style="max-width:130px;max-height:150px" /><div style="width:130px;">' + item.name.first + ' ' + item.name.last + '</div>')
        win.document.write('</div>')
      }
      win.document.write('</div>')
    }

    return {
      sortBy,
      sortedPeople,
      emitClick,
      cols,
      sm,
      md,
      lg,
      xl,
      print
    }
  }
}
</script>
