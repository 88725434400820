<template>
  <v-data-table :items="people" :headers="headers" :group-by.sync="groupField" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :loading="isLoading" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }" hide-default-footer disable-pagination>
    <template v-slot:item.photo="{ item }">
      <v-img :src="item.photo || '/img/no.jpg'" height="80" width="60" />
    </template>
    <template v-slot:group.header="{ group, groupBy }">
      <td class="text-start" :colspan="headers.length">{{ groupLabel(groupBy[0]) }} - {{ group }}</td>
    </template>
  </v-data-table>
</template>
<script>
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    people: {
      type: Array,
      default: () => { return [] }
    },
    group: String,
    sortable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const sortBy = computed({
      get: () => {
        return root.$store.state.greatscots.resultOptions.sortBy || [ 'name.last' ]
      },
      set: (value) => {
        root.$store.commit('greatscots/setResultOption', { field: 'sortBy', value })
        if (!props.sortable) emit('reload')
      }
    })
    const sortDesc = computed({
      get: () => {
        return root.$store.state.greatscots.resultOptions.sortDesc || [ 0 ]
      },
      set: (value) => {
        root.$store.commit('greatscots/setResultOption', { field: 'sortDesc', value })
        if (!props.sortable) emit('reload')
      }
    })
    const groupField = computed({
      get: () => { return props.group },
      set: () => {}
    })
    const show = computed(() => {
      if ('settings' in root.$store.state.user && 'directoryColumns' in root.$store.state.user.settings) {
        return root.$store.state.user.settings.directoryColumns
      } else {
        return {
          dep: true,
          phone: false,
          hall: false,
          room: false,
          box: true,
          job: true
        }
      }
    })
    const headers = computed(() => {
      const arr = [
        { text: 'Photo', value: 'photo' },
        { text: 'Last Name', value: 'name.last' },
        { text: 'Preferred', value: 'name.first' },
        { text: 'Category', value: 'person' },
        { text: 'Email', value: 'email' }
      ]
      if (show.value.dep) arr.push({ text: 'Department', value: 'location.department' })
      if (show.value.phone) arr.push({ text: 'Phone', value: 'phone' })
      if (show.value.hall) arr.push({ text: 'Hall', value: 'location.hall' })
      if (show.value.room) arr.push({ text: 'Office/Room', value: 'location.room' })
      if (show.value.box) arr.push({ text: 'Box Number', value: 'location.box' })
      if (show.value.job) arr.push({ text: 'Job Title', value: 'jobTitle' })
      return arr
    })

    function groupLabel (group) {
      if (group === undefined) return ''
      if (group === 'person') return 'Category'
      if (group === 'group') return 'Group'
      let temp = group.split('.')
      if (temp[0] === 'location') return temp[1].substr(0, 1).toUpperCase() + temp[1].substr(1)
      return group
    }

    function imageSource (item) {
      if ('image' in item && item.image === 'none') {
        return '/img/no.jpg'
      }
      return '/img/upload/scotscard/' + item._id + '.jpg'
    }

    const printHeaders = ref([
      { text: 'Photo', value: 'photo' },
      { text: 'Last Name', value: 'name.last' },
      { text: 'Preferred', value: 'name.first' },
      { text: 'Category', value: 'person' },
      { text: 'Email', value: 'email' },
      { text: 'Department', value: 'location.department' },
      { text: 'Phone', value: 'phone' },
      { text: 'Hall', value: 'location.hall' },
      { text: 'Office/Room', value: 'location.room' },
      { text: 'Box Number', value: 'location.box' },
      { text: 'Job Title', value: 'jobTitle' }
    ])
    function print (win, { fields, printHeadersInGroups }) {
      console.log(fields)
      if (props.group == null || props.group === undefined || props.group === '') {
        win.document.write('<table cellpadding="3" cellspacing="6" style="width:100%">')
        win.document.write('<thead>')
        printHeaderRow(win, fields)
        win.document.write('</thead><tbody>')
        for (let i = 0; i < props.people.length; i++) {
          let person = props.people[i]
          win.document.write('<tr>')
          for (let j = 0; j < fields.length; j++) {
            const { value } = printHeaders.value.filter(({ value }) => value === fields[j])[0]
            win.document.write('<td>')
            if (value === 'photo') {
              win.document.write('<img src="' + (person.photo || 'http://tecd-moon.covenant.edu:8080/img/no.jpg') + '" style="max-width:60px;max-height:100px" />')
            } else if (value in person) {
              const val = person[value]
              if (val == null || val === 'null') continue
              win.document.write(val)
            } else if (value.indexOf('.') > 0) {
              let temp = value.split('.')
              if (temp[0] in person && temp[1] in person[temp[0]]) {
                const val = person[temp[0]][temp[1]]
                if (val == null || val === 'null') continue
                win.document.write(val)
              }
            }
            win.document.write('</td>')
          }
          win.document.write('</tr>')
        }
        win.document.write('</tbody></table>')
      } else {
        let groups = {}
        for (let i = 0; i < props.people.length; i++) {
          let group = props.people[i][props.group]
          if (!(group in groups)) groups[group] = []
          groups[group].push(props.people[i])
        }
        win.document.write('<table cellpadding="3" cellspacing="6" width="100%">')
        if (!printHeadersInGroups) printHeaderRow(win, fields)
        for (let group in groups) {
          // win.document.write('<h3>' + groupLabel(props.group) + ' - ' + group + '</h3>')
          // win.document.write('<table cellpadding="3" cellspacing="6">')
          win.document.write('<tr><td colspan="' + fields.length + '" style="background-color:beige;font-weight:bold;font-size:1.2em">' + groupLabel(props.group) + ' - ' + group + '</td></tr>')
          if (printHeadersInGroups) printHeaderRow(win, fields)
          for (let k = 0; k < groups[group].length; k++) {
            let person = groups[group][k]
            win.document.write('<tr>')
            for (let j = 0; j < fields.length; j++) {
              const { value } = printHeaders.value.filter(({ value }) => value === fields[j])[0]
              if (value === props.group) continue
              win.document.write('<td>')
              if (value === 'photo') {
                win.document.write('<img src="' + (person.photo || process.env.VUE_APP_BASE_URL + '/img/no.jpg') + '" style="max-width:60px;max-height:100px" />')
              } else if (value in person) {
                const val = person[value]
                if (val == null || val === 'null') continue
                win.document.write(val)
              } else if (value.indexOf('.') > 0) {
                let temp = value.split('.')
                if (temp[0] in person && temp[1] in person[temp[0]]) {
                  const val = person[temp[0]][temp[1]]
                  if (val == null || val === 'null') continue
                  win.document.write(val)
                }
              }
              win.document.write('</td>')
            }
            win.document.write('</tr>')
          }
          // win.document.write('</tbody></table>')
        }
        win.document.write('</table>')
      }
    }

    function printHeaderRow (win, fields) {
      win.document.write('<tr>')
      for (let i = 0; i < fields.length; i++) {
        let { text, value } = printHeaders.value.filter(({ value }) => value === fields[i])[0]
        if (value === props.group) continue
        win.document.write('<td style="font-weight:bold">' + text + '</td>')
      }
      win.document.write('</tr>')
    }

    return {
      sortBy,
      sortDesc,
      groupField,
      headers,
      groupLabel,
      imageSource,
      printHeaders,
      print
    }
  }
}
</script>
