<template>
  <v-container>
    <v-row>
      <v-col v-for="person in sortedPeople" :key="person._id" cols="12" sm="6" lg="4" xl="3">
        <card-single :person="person" class="ma-1"></card-single>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
 @media print {
   div.col-12 {
     max-width: 50%;
   }
 }
</style>
<script>
import { computed } from '@vue/composition-api'

export default {
  components: {
    CardSingle: () => import('./CardSingle')
  },
  props: {
    people: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, { root }) {
    const sortBy = computed(() => root.$store.state.greatscots.resultOptions.sortBy)
    const sortedPeople = computed(() => props.people.sort((a, b) => {
      for (const f of sortBy.value) {
        const [top, bottom] = f.split('.')
        if (a[top][bottom] < b[top][bottom]) return -1
        else if (a[top][bottom] > b[top][bottom]) return 1
      }
      return 0
    }))

    function print (win) {
      for (const item of sortedPeople.value) {
        win.document.write('<div style="display:inline-block;width:330px;height:150px;vertical-align:top;margin:.3em;border:1px solid #CCCCCC;padding:10px;">')
        win.document.write('<img src="' + (item.photo || process.env.VUE_APP_BASE_URL + '/img/no.jpg') + '" style="max-width:100px;max-height:130px;display:inline-block;vertical-align:top" />')
        win.document.write('<div style="width:220px;display:inline-block;vertical-align:top;margin-left:10px">')
        win.document.write('<h3 style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin:.3em 0 .4em 0;">' + item.name.first + ' ' + item.name.last + '</h3>')
        if (item.jobTitle) win.document.write('<div>' + item.jobTitle + '</div>')
        if (item.location.department) win.document.write('<div>' + item.location.department + '</div>')
        if (item.email) win.document.write('<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis">' + item.email + '</div>')
        if (item.phone) win.document.write('<div>' + item.phone + '</div>')
        if (item.location.room) win.document.write('<div>' + (item.person === 'Student' ? 'Room: ' : 'Office: ') + item.location.room + '</div>')
        if (item.location && item.location.box) win.document.write('<div>Campus Box #' + item.location.box.replace(/[^0-9]/g, '') + '</div>')
        win.document.write('</div>')
        win.document.write('</div>')
      }
      win.document.write('</div>')
    }

    return {
      sortBy,
      sortedPeople,
      print
    }
  }
}
</script>
